<template>
  <div class="container">
    <div class="banner">
      <div class="banner-center">
        <el-image :src="require('../../assets/image/layout/banner-center.jpg')"></el-image>
      </div>
      <div class="banner-main">
        <el-image :src="require('../../assets/image/layout/title.png')"></el-image>
        <el-image :src="require('../../assets/image/layout/pic.png')"></el-image>
      </div>
    </div>
    <div class="nav">
      <div class="nav-content">
        <el-row type="flex" justify="space-between">
          <el-col :span="16">
            <el-menu
              :default-active="routePath"
              class="el-menu-demo"
              mode="horizontal"
              text-color="#484848"
              active-text-color="#198AF7"
			  router
            >
              <el-menu-item index="/index" v-if="hasHomePageAndMsg">
                <i class="el-icon-s-home"></i> 首页
              </el-menu-item>
              <template v-if="roleKey == 'teacher'">
              <!-- <template> -->
                <el-menu-item index="/dynamic">班级动态</el-menu-item>
                <el-menu-item index="/quality">学生综素</el-menu-item>
                <el-menu-item index="/manager">班级管理</el-menu-item>
              </template>
              <template v-if="roleKey == 'student'">
              <!-- <template v-if="false"> -->
                <el-menu-item index="/classmate">同学动态</el-menu-item>
                <el-menu-item index="/myrelease">我的发布</el-menu-item>
                <el-menu-item index="/myquality">我的综素</el-menu-item>
              </template>

              <template v-if="roleKey == 'parents'">
                <el-menu-item index="/myquality">学生综素</el-menu-item>
              </template>
			  
			  <template v-if="roleKey == 'ban_wei'">
			    <el-menu-item index="/bwDynamic">班级动态</el-menu-item>
			    <el-menu-item index="/bwQuality">学生综素</el-menu-item>
			  </template>
			  
			  <template v-if="roleKey == 'xin_li_plan'">
			    <el-menu-item index="/ptTeachPlan">学生综素</el-menu-item>
			  </template>
             
              <el-menu-item index="/message" v-if="hasHomePageAndMsg">
                消息中心
                <el-badge v-show="totalMSgNum > 0" :value="totalMSgNum" class="item"> </el-badge>
              </el-menu-item>
            </el-menu>
          </el-col>
          
          <!-- 头像 -->
          <el-col :span="4">
            <el-dropdown trigger="click" @command="handleCommand">
              <el-row :gutter="10" type="flex" justify="end" align="middle">
                <el-col :span="12">
                  <div class="userimg">
                    <el-avatar v-if="$store.state.user.headUrl"
                      :src="imgSrc + $store.state.user.headUrl"
                    ></el-avatar>
                    <el-avatar v-else
                      src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
                    ></el-avatar>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="username">
                    <p>{{$store.getters.name}}</p>
                  </div>
                </el-col>
                <!-- <el-col :span="8">
                  <span class="el-dropdown-link">
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                </el-col> -->
              </el-row>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="/personalCenter" icon="el-icon-user"
                  >个人中心</el-dropdown-item
                >
                <el-dropdown-item v-if="roleKey == 'parents'" command="/switch" icon="el-icon-sort"
                  >切换学生</el-dropdown-item
                >
                <el-dropdown-item command="/logout" icon="el-icon-circle-close"
                  >退出登录</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>
      </div>
    </div>

    <el-dialog
      title="选择学生"
      :visible.sync="dialogVisible"
      width="700px"
      center  
      :close-on-click-modal="false"
    >
      <div class="student">
        <div class="item" v-for="(item,index) in student" :key="index" @click="handleLogin(item)">
          <el-avatar v-if="item.headUrl" :src="imgSrc + item.headUrl"></el-avatar>
          <el-avatar v-else src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
          <p>{{item.userName}}</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { removeToken } from '@/utils/auth'
export default {
  data() {
    return {
      imgSrc: this.imageSrc(),
      routePath: '',
      roleKey: this.$store.getters.roles[0].roleKey,
      dialogVisible: false,
      student: [],
	  hasHomePageAndMsg: false,
	  totalMSgNum: 0
    };
  },
  created() {
    this.getPath()
	// 是否有首页和消息中心的展示权限
	this.hasHomePageAndMsg = this.roleKey != 'ban_wei' && this.roleKey != 'xin_li_plan'
	if (this.hasHomePageAndMsg) {
		this.$store.dispatch('user/getAllUnreadMsgNum').then(res => {
			this.totalMSgNum = this.getUnReadMsgNum('totalMessageNum')
		})
	}
    this.student = this.$store.getters.student
  },
  watch: {
    $route: "getPath",
  },
  methods: {
	clrToken() {
		removeToken()
	},
    getPath() {
      // console.log(this.$route.path);
      if(this.$route.path.indexOf('/dynamic') !=-1) {
        this.routePath = '/dynamic'
      }else if(this.$route.path.indexOf('/classmate') !=-1){
        this.routePath = '/classmate'
      }else if(this.$route.path.indexOf('/myrelease') !=-1){
        this.routePath = '/myrelease'
      }else if(this.$route.path.indexOf('/myquality') !=-1){
        this.routePath = '/myquality'
      }else if(this.$route.path.indexOf('/quality') !=-1){
        this.routePath = '/quality'
      }else if(this.$route.path.indexOf('/manager') !=-1){
        this.routePath = '/manager'
      }else if(this.$route.path.indexOf('/message') !=-1){
        this.routePath = '/message'
      }else if(this.$route.path.indexOf('/bwDynamic') != -1) {
		this.routePath = '/bwDynamic'
	  }else if(this.$route.path.indexOf('/bwQuality') != -1) {
		this.routePath = '/bwQuality'
	  }else if(this.$route.path.indexOf('/ptTeachPlan') != -1) {
		this.routePath = '/ptTeachPlan'
	  } else {
        this.routePath = this.$route.path
      }
    },
    handleCommand(command) {
      if (command == "/logout") {
        this.$confirm("确定注销并退出系统吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$store.dispatch("user/logout").then(() => {
              this.$router.push("/login");
              this.$store.dispatch('ClearGetters')
            });
          })
          .catch(() => {
            return false;
          });
      } else if(command == '/switch') {
        this.dialogVisible = true
      } else {
        this.$router.push(command);
      }
    },
    handleLogin(row) {
      // console.log(row);
      let userInfo = {
        username: row.loginName,
        password: row.password,
      }
      let parents = {
        loginName: JSON.parse(sessionStorage.getItem('parents')).loginName,
        clientId: JSON.parse(sessionStorage.getItem('parents')).clientId,
        studentId: row.userId,
      }
      this.$store.dispatch("user/login", userInfo).then(res=> {
        this.$store.dispatch("user/getUserInfo", parents).then(() =>{
          this.dialogVisible = false
          sessionStorage.setItem('parents',JSON.stringify(parents))
          this.$router.push("/skip");
        })
      });
    }
  },
};
</script>

<style lang='scss' scoped>
.container {
  width: 100%;
  z-index: 1000;
  .banner {
    height: 243px;
    background: #1084f5;
    position: relative;
    .banner-center{
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      .el-image{
        width: 1920px;
      }
    }
    .banner-main{
      max-width: 1400px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .nav {
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.11);
    .nav-content {
      max-width: 1400px;
      height: 62px;
      padding-top: 2px;
      margin: 2px auto 0;
      box-sizing: border-box;
      .el-menu>.el-menu-item{
        font-weight: bold;
      }
      .el-menu.el-menu--horizontal {
        border: none;
      }
      .el-menu--horizontal>.el-menu-item.is-active{
          border-bottom: 4px solid #409EFF;
      }
      .el-menu>.el-menu-item i{
        font-size: 20px;
        color: #198AF7;
      }
      .item {
        margin-top: -25px;
        margin-left: -10px;
      }
      .el-dropdown {
        float: right;
        cursor: pointer;
        .userimg {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: #a8a8a8;
          margin: 10px;
          overflow: hidden;
        }
        .username {
          font-size: 16px;
          margin-left: 5px;
          white-space: nowrap;
        }
        .el-dropdown-link {
          margin-left: 5px;
        }
      }
    }
  }
  .student {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .item {
      cursor: pointer;
      .el-avatar {
        width: 100px;
        height: 100px;
      }
      p {
        text-align: center;
      }
    }
  }
}
</style>