<template>
    <div class="container">
        <Header />
        <el-main>
            <div class="main">
                <router-view />
            </div>
        </el-main>
        <Footer />
    </div>
</template>

<script>
import Header from './components/header'
import Footer from './components/footer'
export default {
    data() {
        return {

        }
    },
    components: {
        Header,
        Footer,
    },
    methods: {}
}
</script>

<style lang='scss' scoped>
.container {
    width: 100%;
    overflow: hidden;
    .el-main {
        background: #DEEDF8;
        padding-top: 0px;
        padding-bottom: 0;
    .main {
        max-width: 1400px;
        min-height: calc(100vh - 307px - 268px);
        margin: 0 auto;
    }
    }
}
</style>